import React from 'react';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import './TermEtymology.css';
import { getPropsContaining, getUniquePropIds } from '../../common/utils';

const TermRelatedDefEtymologies = ({ def }) => {
    const etynums = Array.from(
        { length: def.related_definitions_etymologies_ss.length },
        (_, i) => i + 1
    );
    const etyms = etynums.map((i, n) => {
        const prop_regex = `related_definitions_etymology_${i}`;
        const props = getPropsContaining(def, prop_regex, 'starts');
        const etym = {
            id: `${def.id}-etym-${i}`,
            content: '',
            header: '',
            type: '',
        };
        props.map((pnm, pi) => {
            if (pnm.includes('content')) {
                etym.content = def[pnm];
            } else if (pnm.includes('head')) {
                etym.header = def[pnm];
            } else if (pnm.includes('type')) {
                etym.type = def[pnm];
            }
        });
        return etym;
    });

    return (
        <div className="sui-etymology__wrapper mb-4">
            <div className="sui-etymology__content">
                {etyms.map((et, eti) => {
                    return (
                        <TermRelatedDefEtymology
                            key={`trdf-${eti}`}
                            data={et}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const TermRelatedDefEtymology = ({ data }) => {
    const head = data?.head || 'Etymology';
    return (
        <div key={data.id} className="sui-etymology__content-items mt-3 mb-0">
            <div className="sui-etymology__heading">{head}</div>
            <span className="sui-etymology__text">
                {data?.type && (
                    <span className="sui-etymology__type label">
                        {data.type}:{' '}
                    </span>
                )}
                {data.content}
            </span>
        </div>
    );
};

export default TermRelatedDefEtymologies;
